import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style.css';

import {Container,Row,Col,Jumbotron,Alert } from 'react-bootstrap';



class FPDescrip extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
       name: props.name,
       
   };
  }

  render()
  {
    return(
      <div className="DescriptionBox">
        <Jumbotron className="text-dark" id="descrip">
          <h1>Sports Scheduling Made Easy!</h1>
          <p>
            Taking the hassle out of making schedules.<br/>
            Simply fill out some information and we will take it from there.<br/> Go Blues!
          </p>
        </Jumbotron>
        <Alert variant="danger" id="fpADAlert">
        <h3>Athletic Directors!!!</h3>
        <p>Please provide the requested information below no later than March 27th.
           The SlamaTools team has been working diligently since last fall to create a much fast system to develop the Field Hockey schedule.
           The Field Hockey schedule for Fall 2020 is expected to be in your inbox by end of day April 1st.
           Please do not schedule any events  from March 27th to April 1st which may conflict with Field Hockey.
        </p>
        </Alert>
      </div>

    );
  }
}

class FrontPage extends React.Component {  

  //Instantiates the month object  
  constructor(props) {
    super(props);
    this.state = {
        name: props.name,
        
    };
  }
  render() {
        
    return (
      <Container className='bg-dark text-white' id="Pagebox">
               <Row><Col> <FPDescrip /></Col></Row>
       </Container>
    );
  }
}


ReactDOM.render(
    <FrontPage />,
    document.getElementById('root')
    );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

export default FrontPage;
